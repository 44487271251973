import BirdiPrice from 'components/birdi-price/birdi-price.component';
import Disclaimer from 'components/disclaimer/disclaimer.component';
import React, { useCallback } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from 'ui-kit/spinner/spinner';
import Button from 'ui-kit/button/button';
import { formatPrice } from 'schema/price.schema';
import { hasAdjudicatedPrice } from 'util/cart';
import { PrescriptionInformationProps } from './types';
import { useEasyRefill } from 'hooks/useEasyRefill';

export const PrescriptionInformation = ({
    t,
    adjudicatedRxs,
    prescriptions,
    handleRemoveRx,
    removingRx,
    birdiPrices,
    orderConfirmation = false
}: PrescriptionInformationProps) => {
    const removeRx = useCallback(
        (rxNumber: string) => {
            if (handleRemoveRx) {
                return handleRemoveRx(rxNumber);
            }
        },
        [handleRemoveRx]
    );

    const { showBirdiPricing, showRxCost } = useEasyRefill();

    return (
        <>
            <Spinner isVisible={removingRx ?? false} t={t} />
            <Container className="cart-prescription" fluid>
                {Object.entries(adjudicatedRxs).map(([rxNumber, item]) => {
                    const currentPrescription = prescriptions.find((obj: any) => {
                        return obj.rxNumber === rxNumber;
                    });

                    const birdiPrice = birdiPrices.find((rx) => rx.rxNumber === rxNumber);

                    return (
                        <Row
                            key={currentPrescription?.rxNumber}
                            className={`cart-prescription--item ${orderConfirmation && 'order-confirmation'}`}
                        >
                            <Col className="d-flex align-items-stretch flex-column">
                                <h3 className="cart-prescription--header">
                                    {currentPrescription?.dispensedProductName}
                                </h3>
                                <div className="cart-prescription--product-info">
                                    {currentPrescription?.fillQuantity && (
                                        <div>
                                            {t('pages.cart.quantity', {
                                                fillQuantity: currentPrescription?.fillQuantity
                                            })}
                                        </div>
                                    )}
                                    {(currentPrescription?.dispensedProductStrength ||
                                        currentPrescription?.dispensedProductUnitOfMeasure) && (
                                        <div>
                                            {currentPrescription?.dispensedProductStrength}
                                            {currentPrescription?.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}
                                    {currentPrescription?.dispensedProductDosageForm && (
                                        <div>{currentPrescription?.dispensedProductDosageForm}</div>
                                    )}
                                </div>
                            </Col>
                            <Col sm={12} lg={'auto'} className="text-left text-lg-right mt-3 mt-lg-0">
                                <div className="d-flex flex-column-reverse flex-lg-column">
                                    {!orderConfirmation && (
                                        <div className="mt-2 mb-2 mt-lg-0">
                                            <Button
                                                type="button"
                                                variant="text"
                                                className="p-0 mb-3 left-n1 ml-lg-auto btn-remove"
                                                label={t('pages.easyRefill.review.remove')}
                                                disabled={removingRx}
                                                onClick={() => {
                                                    removeRx(rxNumber);
                                                }}
                                                trashIcon
                                            />
                                        </div>
                                    )}
                                    {(hasAdjudicatedPrice(item, currentPrescription) ||
                                        (!hasAdjudicatedPrice(item, currentPrescription) && showRxCost(item))) && (
                                        <React.Fragment>
                                            <div>
                                                <div>
                                                    <h3 className="cart-total--title">
                                                        <span>
                                                            {formatPrice(
                                                                showBirdiPricing ? birdiPrice?.price : item.patientCopay
                                                            )}
                                                        </span>
                                                    </h3>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="h6 cart-total--description mr-0 ">
                                                        <BirdiPrice
                                                            accountHasInsurance={!showBirdiPricing}
                                                            insuredText={t('pages.cart.rxItemTotal')}
                                                            showIfInsured={!item.patientCopay}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </Col>
                            {!item.patientCopay && !orderConfirmation && (
                                <Col className="mt-3" sm={12} lg={'auto'}>
                                    <Disclaimer
                                        disclaimerText={t('pages.easyRefill.review.lineItemNoCopayDisclaimer')}
                                    />
                                </Col>
                            )}
                        </Row>
                    );
                })}
            </Container>
        </>
    );
};
