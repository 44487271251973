import React, { useEffect } from 'react';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CartTotal } from 'components/easy-refill/cart-total.component';

import { EXPEDITED_SHIPPING_COST } from 'util/cart';

//  misc ui-kit
import './index.style.scss';
import { PrescriptionContentSectionHeader } from 'components/prescription-content-section-header';
import {
    easyRefillAjudicatedRxsSelector,
    easyRefillExpeditedShippingSelector,
    easyRefillRxsSelector,
    easyRefillRxsToOrderSelector,
    easyRefillOrderInvoiceNumberSelector,
    easyRefillPatientAddressSelector,
    easyRefillPatientPaymentCardSelector
} from 'state/easy-refill/easy-refill.selectors';
import { PrescriptionInformation } from 'components/easy-refill/prescription-information.component';
import { drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugSelector } from 'state/drug/drug.selectors';
import { useEasyRefill } from 'hooks/useEasyRefill';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import { replaceStringWith } from 'util/string';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import Footer from 'components/footer/footer.component';

const EasyRefillOrderConfirmation = ({ data }: { data: GatsbyTypes.EasyRefillOrderConfirmationDataQuery }) => {
    const { blueSkyBackground } = data;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const adjudicatedRxs = useSelector(easyRefillAjudicatedRxsSelector);
    const easyRefillRxs = useSelector(easyRefillRxsSelector);
    const expeditedShipping = useSelector(easyRefillExpeditedShippingSelector);
    const easyRefillRxsToOrder = useSelector(easyRefillRxsToOrderSelector);
    const orderNumber = useSelector(easyRefillOrderInvoiceNumberSelector);
    const defaultShippingAddress = useSelector(easyRefillPatientAddressSelector);
    const defaultPaymentCard = useSelector(easyRefillPatientPaymentCardSelector);

    const { showBirdiPricing, estimatedRxCost } = useEasyRefill();

    useEffect(() => {
        if (showBirdiPricing) {
            dispatch(
                drugDiscountPriceRoutine.trigger({
                    prescriptions: easyRefillRxsToOrder,
                    forceBirdiInsurance: ALLOW_INSURED_BIRDI_PRICE
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBirdiPricing, dispatch]);

    const { drugDiscountPrices } = useSelector(drugSelector);

    return (
        <>
            <WorkflowLayout
                backgroundImage={blueSkyBackground}
                useRoundedCorners={false}
                metaData={{ nodeTitle: 'Easy Refill' }}
                anonymousOnly={true}
                className="easy-refill-cart"
                hideNavLinks
            >
                <WorkflowLayoutFormWrapper
                    title={t('pages.easyRefill.orderConfirmation.headlineText')}
                    eyebrowText={t('pages.easyRefill.orderConfirmation.eyebrowText')}
                    className="easy-refill-cart-layout-form-wrapper"
                >
                    <>
                        <Row className="easy-refill-cart-order-message">
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <h4>{t('pages.easyRefill.orderConfirmation.orderMessage', { orderNumber })}</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <PrescriptionContentSectionHeader
                                    label={t('pages.easyRefill.orderConfirmation.sectionHeaders.prescriptionsOrdered')}
                                    className="easy-refill-layout-content-section-header"
                                />
                                <PrescriptionInformation
                                    t={t}
                                    adjudicatedRxs={adjudicatedRxs}
                                    prescriptions={easyRefillRxs}
                                    birdiPrices={drugDiscountPrices}
                                    orderConfirmation
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <CartTotal
                                    t={t}
                                    hasUnknownPrice={true}
                                    initialOrderPrice={estimatedRxCost}
                                    currentShippingPrice={expeditedShipping ? EXPEDITED_SHIPPING_COST : 0}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <PrescriptionContentSectionHeader
                                    label={t('pages.easyRefill.orderConfirmation.sectionHeaders.shippingInformation')}
                                    className="easy-refill-layout-content-section-header"
                                />
                                <Row>
                                    <Col className="easy-refill-cart-section-content p-0">
                                        <div className="easy-refill-cart-default-address-label">
                                            <h6>
                                                {t(
                                                    'pages.easyRefill.orderConfirmation.shippingInformation.defaultAddressLabel'
                                                )}
                                            </h6>
                                        </div>
                                        <div className="easy-refill-cart-default-address">
                                            {defaultShippingAddress && (
                                                <>
                                                    <span className="easy-refill-cart-section-sentence">
                                                        {defaultShippingAddress.address1}{' '}
                                                        {defaultShippingAddress.address2
                                                            ? defaultShippingAddress.address2
                                                            : ''}
                                                    </span>
                                                    <span className="easy-refill-cart-section-sentence">
                                                        {defaultShippingAddress.city}, {defaultShippingAddress.state}{' '}
                                                        {defaultShippingAddress.zipcodeFour
                                                            ? `${defaultShippingAddress.zipcode}-${defaultShippingAddress.zipcodeFour}`
                                                            : defaultShippingAddress.zipcode}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <PrescriptionContentSectionHeader
                                    label={t('pages.easyRefill.orderConfirmation.sectionHeaders.paymentInformation')}
                                    className="easy-refill-layout-content-section-header"
                                />
                                <Row>
                                    <Col className="easy-refill-cart-section-content p-0">
                                        <div className="easy-refill-cart-default-payment-method-label">
                                            <h6>
                                                {t(
                                                    'pages.easyRefill.orderConfirmation.paymentInformation.primaryPaymentMethod'
                                                )}
                                            </h6>
                                        </div>
                                        <div className="easy-refill-cart-default-payment-method">
                                            {defaultPaymentCard && (
                                                <>
                                                    <span>
                                                        {t(
                                                            'pages.easyRefill.orderConfirmation.paymentInformation.cardEnding',
                                                            {
                                                                cardType: defaultPaymentCard.cardType,
                                                                endingDigits: replaceStringWith(
                                                                    defaultPaymentCard.secureCardNumber,
                                                                    new RegExp(/\*/g),
                                                                    ''
                                                                )
                                                            }
                                                        )}
                                                        <br />
                                                        {defaultPaymentCard.cardName}
                                                        <br />
                                                        Exp. {defaultPaymentCard.cardExpiration}
                                                    </span>
                                                    <CreditCardIcon
                                                        className="payment-card__credit-card-icon"
                                                        variant={defaultPaymentCard.cardType}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} lg={{ span: 10, offset: 1 }}>
                                <Row>
                                    <Col className="easy-refill-cart-section-content manage-prescriptions p-0">
                                        <span className="easy-refill-cart-section-sentence">
                                            {t('pages.easyRefill.orderConfirmation.managePrescriptions.sentenceOne')}
                                            <a href="/sign-in">
                                                {t('pages.easyRefill.orderConfirmation.managePrescriptions.signIn')}
                                            </a>
                                            {t('pages.easyRefill.orderConfirmation.managePrescriptions.sentenceTwo')}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                </WorkflowLayoutFormWrapper>
            </WorkflowLayout>
            <Footer
                copyrightYear={new Date().getFullYear().toString()}
                paths={[]}
                legalPaths={data.allMenuLinkContentFooterLegal.nodes}
                physicalAddress={data.allBlockContentAddresses.nodes[0]?.field_physical_address}
                mailingAddress={data.allBlockContentAddresses.nodes[0]?.field_mailing_address}
            />
        </>
    );
};

export default EasyRefillOrderConfirmation;

export const query = graphql`
    query EasyRefillOrderConfirmationData($language: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/transfer-rx-health.jpg" }) {
            id
            childImageSharp {
                fluid {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                }
                gatsbyImageData(formats: [AUTO])
            }
        }
        allMenuLinkContentFooterLegal(sort: { order: ASC, fields: weight }, filter: { enabled: { eq: true } }) {
            nodes {
                link {
                    url
                }
                label: title
                langcode
            }
        }
        allBlockContentAddresses(sort: { fields: changed, order: DESC }) {
            nodes {
                field_physical_address
                field_mailing_address
            }
        }
    }
`;
